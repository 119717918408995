import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import {
  termCategory,
  termListing,
  termName
} from '../components/layout.module.css';

const TermsPage = ({ data }) => (
  <Layout>
    <div>
      {data.allMarkdownRemark.group.map((group) => (
        <React.Fragment key={group.fieldValue}>
          <h2 className={termCategory}>{group.fieldValue}</h2>
          <div className={termListing}>
            {group.edges.map(({ node }) => (
              <div className={termName} key={node.id}>
                <Link to={node.fields.slug} title={node.frontmatter.summary}>
                  {node.frontmatter.title}
                </Link>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  </Layout>
);

export const query = graphql`
  query BackendTermIndexQuery {
    allMarkdownRemark(
      filter: { fields: { type: { eq: "term" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        edges {
          node {
            id
            fileAbsolutePath
            frontmatter {
              title
              date(formatString: "YYYY-MM-DD")
              summary
            }
            fields {
              slug
              type
            }
          }
        }
      }
    }
  }
`;

export default TermsPage;
